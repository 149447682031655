<template>
    <div class="register">

        <el-form ref="form" class="form" :model="form" label-width="120px" method="post"
            @submit.prevent="Register">
                    <h3>Sign up</h3>
                    <p>Already have an account? <router-link to="">Sign in</router-link>
</p>

                <el-form-item label="Name" label-width="auto" label-position="top">
                    <el-input v-model="form.name" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="Full name" label-width="auto" label-position="top">
                    <el-input v-model="form.full_name" placeholder=""></el-input>
                </el-form-item>


                <el-form-item label="Email" label-width="auto" label-position="top">
                    <el-input v-model="form.email" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="Password" label-width="auto" label-position="top">
                    <el-input v-model="form.password" placeholder="" show-password></el-input>
                </el-form-item>

                <el-form-item label="Confirm Password" label-width="auto" label-position="top">
                    <el-input v-model="form.confirm_password" placeholder="" show-password></el-input>
                </el-form-item>

                  <el-checkbox v-model="checked">I agree to the Terms of Service and Privacy Policy</el-checkbox>



                 <el-form-item>
    <el-button type="primary" @click.prevent="Register">Sign up</el-button>
  </el-form-item>
        </el-form>





<el-dialog

  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
      <div class="register">

        <el-form ref="form" class="form" :model="form" label-width="120px" method="post"
            @submit.prevent="login">
                    <h3>Sign up</h3>
                      <p>Don't have an account yet? <router-link to="">Sign up</router-link></p>




                <el-form-item label="Email" label-width="auto" label-position="top">
                    <el-input v-model="form.email" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="Password" label-width="auto" label-position="top">
                    <el-input v-model="form.password" placeholder="" show-password></el-input>
                </el-form-item>

<router-link to="">Forgot Password?</router-link>
                 <el-form-item>
    <el-button type="primary" @click.prevent="login">Sign up</el-button>
  </el-form-item>
        </el-form>



    </div>

</el-dialog>



<el-dialog
  :visible.sync="dialogVisible2"
  width="30%"
  :before-close="handleClose">
    <div class="register register-height">

        <el-form ref="form" class="form" :model="form" label-width="120px" method="post"
            @submit.prevent="Register">
                    <h3>Sign up</h3>
                    <p>Already have an account? <router-link to="">Sign in</router-link></p>

                <el-form-item label="Name" label-width="auto" label-position="top">
                    <el-input v-model="form.name" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="Full name" label-width="auto" label-position="top">
                    <el-input v-model="form.full_name" placeholder=""></el-input>
                </el-form-item>


                <el-form-item label="Email" label-width="auto" label-position="top">
                    <el-input v-model="form.email" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="Password" label-width="auto" label-position="top">
                    <el-input v-model="form.password" placeholder="" show-password></el-input>
                </el-form-item>

                <el-form-item label="Confirm Password" label-width="auto" label-position="top">
                    <el-input v-model="form.confirm_password" placeholder="" show-password></el-input>
                </el-form-item>
                  <el-checkbox v-model="checked">I agree to the Terms of Service and Privacy Policy</el-checkbox>


                 <el-form-item>
    <el-button type="primary" @click.prevent="Register">Sign up</el-button>
  </el-form-item>
        </el-form>



    </div>

</el-dialog>

    </div>
</template>



<script>
  export default {
    data() {
      return {
        checked: true,
        arrMsg:[],

        form: {
          name: '',
          email: '',
          password: '',
          confirm_password: '',
          full_name: ''
        }
      }
    },

    mounted() {

    },
    methods: {
      onSubmit() {
        console.log('submit!');
      },

      async Register(){
                        const res=  await this.axios.post("http://podcasts.arabicreators.com/api/register",this.form).then((r=>{

                                                    this.arrMsg=[]


      if(r.data.code== 400){

           if(Array.isArray(r.data.message)){

          // alert(this.arrMsg.length)

          for (let index = 0; index < r.data.message.length; index++) {
              this.arrMsg.push(r.data.message[index])
          }

          //  alert(this.arrMsg.length)
          //  alert(this.arrMsg[1])

          for (let i = 0; i < this.arrMsg.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg[i]),


             1000)

          }

          }

      }


       else if(r.data.code== 200){
        this.$message({
          message: 'تم التسجيل بنجاح',
          type: 'success'
        });
          localStorage.setItem('token',r.data.data.token)
          localStorage.setItem('userEmail',this.form.email)



          this.$router.push('/')




  }

            }))

      }
    }
  }
</script>


<style lang="scss">

    .register{

        position: relative;


    .form{
    padding: 20px;
    width: 30%;
    margin: auto;
    position: absolute;
    right: 50%;
    transform: translateX(200px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;

        .el-form-item__content{
            margin-left: 0 !important;
        }

    .el-button{
        width: 100%;
        margin-top: 30px;


    }
    }


    }
</style>
