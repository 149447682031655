<template>
  <div class="home">






                   <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <!-- <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button> -->

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>


              <div class="main-content">
            <el-steps :space="100" :active="1" simple>
              <el-step title="Podcast Set up" icon="el-icon-edit"></el-step>
              <el-step title="Add Episode" icon="el-icon-upload"></el-step>
              <!-- <el-step title="Add Audio" icon="el-icon-microphone"></el-step> -->
            </el-steps>

            <div class="inner-content">
              <el-form ref="form" :model="form" label-width="120px" method="post"
            @submit.prevent="create">
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Add a cover image*</h3>
                        <p>
                          Upload a personalized image for your podcast - 1000 x
                          1000 px
                        </p>
                          <button style="display:block; height:35px;margin-bottom:10px" class="fileInput1"  @click.prevent="$refs.fileInput1.click()">اضغط هنا لتحميل صورة  </button>
                          <input type='file' style="display:none" ref="fileInput1" multiple  @change="onfileSelected">
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Pick a name for your podcast*</h3>
                        <p>Pick a unique display name</p>
                        <el-input
                          v-model="form.title"
                          placeholder="Podcast Name"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                  ><div class="grid-content">
                    <el-form-item>
                      <h3>Description*</h3>
                      <p>Write a few lines about your podcast</p>
                      <el-input
                      :rows="6"
                        type="textarea"
                        placeholder="Short description of your podcast. Few sentences are recommended"
                        v-model="form.description"
                      ></el-input>
                    </el-form-item></div
                ></el-col>


                                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Creator’s Name *</h3>
                        <p>Who is the creator of this podcast</p>
                        <el-input
                          v-model="form.name"
                          placeholder="placeholder"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>


                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Copyright</h3>

                        <el-input
                          v-model="form.copyright"
                          placeholder="Copywriter’s Name"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>


                </el-row>
                <!-- <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Explicit</h3>
                        <p>Warn listeners about language or sexual content</p>
                        <el-switch
                          style="display: block"
                          v-model="value2"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Yas"
                          inactive-text="No"
                        >
                        </el-switch>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Type*</h3>
                        <p>What type of podcast you're adding?*</p>
                        <el-switch
                          style="display: block"
                          v-model="value2"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text=" Episodic "
                          inactive-text=" Serial"
                        >
                        </el-switch>
                      </el-form-item></div
                  ></el-col>
                </el-row> -->
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Main category*</h3>
                        <p>Select the main category your podcast falls under</p>
                        <el-select v-model="form.category_id" placeholder="Category"  @change="allSup">
                          <el-option
                            v-for="item in all_category"
                            :key="item"
                            :label="item.title"
                            :value="item.id"

                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Secondary category*</h3>
                        <p>
                          Select as many subcategories as you feel appropriate
                        </p>
                        <el-select
                          v-model="form.sub_categories"
                          placeholder=" Select as many subcategories as you feel appropriate"
                        >
                          <el-option
                            v-for="item in all_sub_category"
                            :key="item.id"
                            :label="item.title"
                            :value="item.title"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Discovery</h3>
                        <p>Customize your podcast for a specific audience</p>

                        <h3>Tags</h3>
                        <p>Add tags so people would find your podcast</p>
                        <!-- <el-input
                          v-model="form.tags"
                          placeholder="Tags:#example #best #sports"
                        ></el-input> -->

                         <input-tag
                          v-model="form.tags"
                          placeholder="Tags:#example #best #sports"
                          add-tag-on-keys="13"
                          class="blogInput"
                        >
              </input-tag>
                      </el-form-item></div
                  ></el-col>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Language*</h3>
                        <p>Select the language of your podcast</p>
                        <el-select
                          v-model="form.lang"
                          placeholder="Select the language of your podcast"
                        >
                          <el-option
                            v-for="(item,key) in all_langs"
                            :key="item"
                            :label="item"
                            :value="key"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Country</h3>
                        <p>Select your podcast's country of origin</p>
                        <el-select
                          v-model="form.country"
                          placeholder="Select your podcast's country of origin"
                        >
                          <el-option
                            v-for="(item,key) in all_countries"
                            :key="item"
                            :label="item"
                            :value="key"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Website</h3>
                        <p>Add your podcast's website</p>
                        <el-input
                          v-model="form.website"
                          placeholder="Website’s url"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="16">
                    <el-form-item>
                      <h3>Action</h3>
                      <p>When do you want to publish?</p>
                          <el-switch
                          style="display: block"
                          v-model="value2"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Schedule for future "
                          inactive-text=" Publish Immediately  "
                        >
                        </el-switch>
                      <!-- <el-button-group>
                        <el-button class="active" type=""> Publish Immediately </el-button>
                        <el-button type=""> Schedule for future </el-button>
                      </el-button-group> -->


                    <el-date-picker v-if="value2"
                      v-model="form.date"
                      type="datetime"
                      value-format="yyyy-MM-dd HH-mm-ss"

                      placeholder="Select date and time"
                    >
                    </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button round>Cancle</el-button>
                      </el-form-item>
                    </div></el-col
                  >

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button type="primary" @click.prevent="create" round>Save</el-button>
                      </el-form-item>
                    </div></el-col
                  >
                </el-row>

                <!--
                <el-col :span="12"><div class="grid-content"></div></el-col>

                <el-col :span="12"><div class="grid-content"></div></el-col> -->
              </el-form>
            </div>
          </div>


         </div
      ></el-col>



    </el-row>



  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      value2: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      acc:undefined,
      user:undefined,

      userAuth:undefined,

      show:true,
      form: [
        {
          url: "",
          name:'',
          title:"",
          description:"",
          copyright:"",
          category_id:"",
          sub_categories:"",
          tags:"",
          country:"",
          lang:"",
          website:"",
          date:""
        },
      ],

      imageSelcteed: null,

      all_langs: undefined,
      all_category: undefined,
      all_countries: undefined,
      all_sub_category: undefined,


      activeIndex: "1",

       arrMsg:[],
    };
  },
  mounted() {



          this.form.name = localStorage.getItem('userName');

        this.axios
      .get("http://podcasts.arabicreators.com/api/all_langs")
      .then((res) => {
        console.log(res);
        this.all_langs = res.data.data;
      });

              this.axios
      .get("http://podcasts.arabicreators.com/api/all_category")
      .then((res) => {
        console.log(res);
        this.all_category = res.data.data;
      });

              this.axios
      .get("http://podcasts.arabicreators.com/api/all_countries")
      .then((res) => {
        console.log(res);
        this.all_countries = res.data.data;
      });




         console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)

      } else if (!this.user) {
        localStorage.removeItem("user")
      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
      } else if (!localStorage.getItem('user')) {
        this.userAuth = null
      }




      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }


        this.show=!this.show


    }))


  },
  methods: {


    allSup(){
      this.axios
      .get(`http://podcasts.arabicreators.com/api/all_sub_category/${this.form.category_id}`)
      .then((res) => {
        console.log(res);
        this.all_sub_category = res.data.data;
      });
    },

    onfileSelected(event) {
      this.imageSelcteed = event.target.files[0];
    },
    create(){
            const dataForm = new FormData();
                  dataForm.append("title", this.form.title);
                  dataForm.append("image",this.imageSelcteed,this.imageSelcteed.name);

                  dataForm.append("description", this.form.description);
                  dataForm.append("copyright", this.form.copyright);
                  // dataForm.append("category_id", this.form.category_id);
                  dataForm.append("sub_categories", this.form.sub_categories);
                  dataForm.append("tags", this.form.tags);
                  dataForm.append("country", this.form.country);
                  dataForm.append("lang", this.form.lang);
                  dataForm.append("website", this.form.website);
                  // dataForm.append("name", this.form.name);
                  // dataForm.append("date", this.form.date);




        this.axios
        .post("http://podcasts.arabicreators.com/api/store_podcast", dataForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })

        .then((res) => {
          console.log(res);

          localStorage.setItem('podcastCode',res.data.message.code)




                    this.arrMsg=[]


        if(res.data.code==400){

          // alert(this.arrMsg.length)

          if(Array.isArray(res.data.message)){


          for (let index = 0; index < res.data.message.length; index++) {
              this.arrMsg.push(r.data.message[index])
          }


          for (let i = 0; i < this.arrMsg.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg[i]),


             1000)

          }

           }

        }


                    else  if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تمت الاضافة بنجاح",
              type: "success",
            });

                        this.$router.push("/addEpisodes");

                    }
        });


    }
  },
};
</script>


<style lang="scss">


.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.main-content {
  .inner-content {
    padding: 20px;
    margin: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 5px;
  }
}

.el-form-item {
  h3 {
    margin-bottom: 2px !important;
  }

  p {
    margin: 0px;
  }
  // width: 49%;
  // float: left;
  text-align: start !important;
}

.inner-content::-webkit-scrollbar {
  display: none;
}

.el-select {
  width: 100%;
}

.el-input__inner,
.el-textarea__inner {
  background-color: #f2f2f2 !important;
}

.inner-content {
  .el-button {
    width: 100%;
  }
}

.el-steps--simple {
  margin: 20px;
}

.el-button-group {
  display: flex !important;
}

.el-button:active {
  background-color: #409eff !important;
  color: white !important;
}

.el-button:focus {
  background-color: #409eff !important;
  color: white !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}


  .el-form .el-form-item .blogInput .el-input__inner {
    text-align: right !important;
    background-color: #f2f2f2  !important;
  }

  .blogInput {
    background-color: #f2f2f2  !important;
  }

  .vue-input-tag-wrapper {
    width: 100%;
    // background-color: #fafafa;
    background-color: #f2f2f2  !important;

    border-radius: 5px;
    height: 40px;
    border: none;
  }

  .vue-input-tag-wrapper .new-tag{
    text-indent: 10px;
  }

  .vue-input-tag-wrapper .input-tag{
    background-color: #113454 !important;
    color: white !important;
    border-color: #113454 !important;
  }


.el-switch{
  margin-bottom: 20px;
}

.el-picker-panel__content tr{
  box-shadow: none !important;
}

</style>
