<template>
  <div class="home">



<el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <!-- <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button> -->

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>


            <div class="main-content">
              <audio-recorder
              upload-url="http://podcasts.arabicreators.com/api/import_rss"
              :attempts="3"
              :time="2"
              :headers="headers"
              :before-recording="callback"
              :pause-recording="callback"
              :after-recording="callback"
              :select-record="callback"
              :before-upload="callback"
              :successful-upload="callback"
              :failed-upload="callback"
            />
            </div>


            <recordSound />


          <!-- <vue-audio-mixer
          :config="config"
          size="medium"
          theme="dark"
          :showPan="true"
          :showTotalTime="true"
        /> -->

         </div
      ></el-col>

    </el-row>

  </div>
</template>

<script >
// @ is an alias to /src
import recordSound from "@/components/recordSound.vue";

import VueAudioMixer from 'vue-audio-mixer';
import 'vue-audio-mixer/dist/vue-audio-mixer.min.css';

export default {
  name: "HomeView",
  components:{
    recordSound,VueAudioMixer
  },

  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      value2: true,
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "Today",
      //       onClick(picker) {
      //         picker.$emit("pick", new Date());
      //       },
      //     },
      //     {
      //       text: "Yesterday",
      //       onClick(picker) {
      //         const date = new Date();
      //         date.setTime(date.getTime() - 3600 * 1000 * 24);
      //         picker.$emit("pick", date);
      //       },
      //     },
      //     {
      //       text: "A week ago",
      //       onClick(picker) {
      //         const date = new Date();
      //         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", date);
      //       },
      //     },
      //   ],
      // },


            is_loaded:false,
      newconfig:{},
      config: {
        "tracks":[
            {
                "title":"Bass",
                "url":"https://api.soundcloud.com/tracks/841840237/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":-30,
                "gain":0.5,
                "muted":false,
                "hidden":false
            },
            {
                "title":"Flutes",
                "url":"https://api.soundcloud.com/tracks/841840234/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":81,
                "gain":1.08,
                "muted":false,
                "hidden":false
            },
            {
                "title":"Perc",
                "url":"https://api.soundcloud.com/tracks/841840222/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":-49,
                "gain":0.85,
                "muted":false,
                "hidden":false
            },
            {
                "title":"Piano",
                "url":"https://api.soundcloud.com/tracks/841840216/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":-60,
                "gain":0.6,
                "muted":false,
                "hidden":false
            },
            {
                "title":"Strings",
                "url":"https://api.soundcloud.com/tracks/841840174/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":-49,
                "gain":0.85,
                "muted":false,
                "hidden":false
            },
            {
                "title":"Bass",
                "url":"https://api.soundcloud.com/tracks/841840237/stream?client_id=ae1dadcc70f054f451de8c6358bcf396",
                "pan":-30,
                "gain":0.5,
                "muted":false,
                "hidden":false
            }
        ],
        "master":{
            "pan":0,
            "gain":1,
            "muted":false
        }
      },




      value1: "",

      form: [
        {
          url: "",
        },
      ],
      tableData: [
        {
          Name: "Tom",
          DateCreated: "2016-05-03",
          Episodes: "No. 189, Los Angeles",
          Status: "open",
          Listens: "no data",
        },
        {
          Name: "Tom",
          DateCreated: "2016-05-03",
          Episodes: "No. 189, Los Angeles",
          Status: "open",
          Listens: "no data",
        },
      ],
      activeIndex: "1",
    };
  },



  mounted() {

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)



      } else if (!this.user) {
        localStorage.removeItem("user")



      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')

      } else if (!localStorage.getItem('user')) {
        this.userAuth = null


      }


      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }





        // this.show=!this.show


    }))
  },

};
</script>


<style lang="scss">
body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}

.home {
  font-family: "Poppins", sans-serif;
}

.el-menu {
  background: transparent !important;
  border-right: none !important;
}

.el-menu-item i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.el-col-4 {
  height: 100vh !important;
}

.aisad {
  background-color: #113454;
  height: 100% !important;
  // padding: 20px;
  color: white;

  .img-logo {
    width: 145px;
    height: 40px;
    padding-left: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-item {
  text-align: start;
  font-size: 16px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #161c33 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  i {
    font-size: 25px !important;
  }

  .userInfo {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #444;
      font-weight: 600;
    }
  }

  .user-image {
    .img-box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.main-content {
  .inner-content {
    padding: 20px;
    margin: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.el-form-item {
  h3 {
    margin-bottom: 2px !important;
  }

  p {
    margin: 0px;
  }
  // width: 49%;
  // float: left;
  text-align: start !important;
}

.inner-content::-webkit-scrollbar {
  display: none;
}

.el-select {
  width: 100%;
}

.el-input__inner,
.el-textarea__inner {
  background-color: #f2f2f2 !important;
}

  .addAudio{
  .el-button {
    width: 80%;
  }
  }


.el-steps--simple {
  margin: 20px;
}

.el-button-group {
  display: flex !important;
}

.el-button:active {
  background-color: #409eff !important;
  color: white !important;
}

.el-button:focus {
  background-color: #409eff !important;
  color: white !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
</style>
