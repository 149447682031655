<template>
  <div class="home">



    <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <!-- <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button> -->

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>

    <div class="main-content">
      <el-tabs v-model="activeName" stretch="true" @tab-click="handleClick">
        <el-tab-pane label="Overview" name="first">
          <div class="podcast-main">
            <div class="podcast-head">
              <div class="podacst-info">
                <div class="img-box">
                  <img
                    v-if="my_Episodes.image"
                    :src="my_Episodes.image"
                    alt=""
                  />
                </div>
                <div class="pod-txt">
                  <h3 v-if="my_Episodes.title">{{ my_Episodes.title }}</h3>
                  <h4 v-if="my_Episodes.user_info.name">
                    {{ my_Episodes.user_info.name }}
                  </h4>
                  <span v-if="my_Episodes.publish_date">{{
                    my_Episodes.publish_date
                  }}</span>
                </div>
              </div>

              <div class="add-Episode">
                <router-link to="/addEpisodes">+ add Episode</router-link>
              </div>
            </div>

            <div class="Summary">
              <h2>Summary</h2>
              <p v-if="my_Episodes.description">
                {{ my_Episodes.description }}
              </p>
            </div>

            <div class="Episodes">
              <div class="heading">
                <h3>Episodes</h3>
                <a href="">all</a>
              </div>

              <div class="" v-if="count_item == 0">
                <img src="../assets/empty-folder.png" alt="" />
                <p>There are no episodes yet</p>
              </div>

              <div
                class="Episode-item"
                v-for="item in sound_item"
                :key="item.id"
              >
                <div class="img-box">
                  <img :src="item.image" alt="" />
                </div>
                <div class="inner-item">
                  <h4>{{ item.title }}</h4>
                  <vue-plyr>
                    <audio controls playsinline>
                      <source :src="item.link" />
                    </audio>
                  </vue-plyr>
                </div>

                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </div>

            <div class="content">
              <h3 class="Analytics" >Analytics</h3>

            <el-row>
              <el-col :span="12"
                ><div class="view-Insights">
                  <h3>Visits</h3>

                  <Pie
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :width="200"
                    :height="200"
                  />

                  </div
              ></el-col>
              <el-col :span="12"
                ><div class="view-Insights">
                  <h3>Gender</h3>

                    <Pie
                    :chart-options="chartOptions"
                    :chart-data="chartData2"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :width="200"
                    :height="200"
                  />
                </div></el-col
              >
            </el-row>
          </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Episodes" name="second">
          <div class="podcast-main">
            <!-- <div class="podcast-head">
              <div class="podacst-info">
                <div class="img-box">
                  <img src="../assets/pod.png" alt="" />
                </div>
                <div class="pod-txt">
                  <h3>podcast name</h3>
                  <h4>podcast caretor</h4>
                  <span>date</span>
                </div>
              </div>

              <div class="add-Episode">
                <a href="">+ add Episode</a>
              </div>
            </div>

            <div class="Summary">
              <h2>Summary</h2>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Deserunt sit ipsum eos et quidem doloremque?
              </p>
            </div> -->

            <div class="Episodes">
              <div class="heading">
                <h3>All Episodes</h3>
              </div>

              <div class="" v-if="count_item == 0">
                <img src="../assets/empty-folder.png" alt="" />
                <p>There are no episodes yet</p>
              </div>

              <div
                class="Episode-item"
                v-for="item in sound_item"
                :key="item.id"
              >
                <div class="img-box">
                  <img :src="item.image" alt="" />
                </div>
                <div class="inner-item">
                  <h4>{{ item.title }}</h4>
                  <vue-plyr>
                    <audio controls playsinline>
                      <source :src="item.link" type="audio/mp3" />
                    </audio>
                  </vue-plyr>
                </div>

                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>

              <!-- <div class="Episode-item">
                <div class="img-box">
                  <img src="../assets/pod.png" alt="" />
                </div>
                <div class="inner-item">
                  <h4>Lorem, ipsum dolor.</h4>
                  <vue-plyr>
                    <audio controls crossorigin playsinline>
                      <source src="../assets/sound.wav" type="audio/WAV" />
                    </audio>
                  </vue-plyr>
                </div>

                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div> -->
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Insights" name="third">
          <!--
             <PieChart /> -->

          <div class="content-Insights">
            <div class="top">
              <el-row>
                <el-col :span="12"
                  ><div class="view border-right">
                    <div class="viewTop">
                      <h3>UNIQUE IMPRESSIONS</h3>
                      <el-popover
                        placement="top-end"
                        width="200"
                        trigger="hover"
                        content="this is content, this is content, this is content"
                      >
                        <i slot="reference" class="el-icon-warning"></i>
                      </el-popover>
                    </div>
                    <span>{{uniqe_view}}</span>
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="view">
                    <div class="viewTop">
                      <h3>TOTAL IMPRESSIONS</h3>
                      <el-popover
                        placement="top-end"
                        width="200"
                        trigger="hover"
                        content="this is content, this is content, this is content"
                      >
                        <i slot="reference" class="el-icon-warning"></i>
                      </el-popover>
                    </div>
                    <span>{{total_view}}</span>
                  </div></el-col>
              </el-row>
            </div>

            <el-row>
              <el-col :span="12"
                ><div class="view-Insights">
                  <h3>Visits</h3>

                  <Pie
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :width="200"
                    :height="200"
                  />

                  </div
              ></el-col>
              <el-col :span="12"
                ><div class="view-Insights">
                  <h3>Gender</h3>

                    <Pie
                    :chart-options="chartOptions"
                    :chart-data="chartData2"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :width="200"
                    :height="200"
                  />
                </div></el-col
              >
            </el-row>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="Distribution" name="fourth"
          >Distribution</el-tab-pane
        > -->
        <el-tab-pane label="Settings" name="fourth">

            <div class="inner-content">
                            <el-form ref="form" :model="form" label-width="120px" method="post"
            @submit.prevent="create">
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Add a cover image*</h3>
                        <p>
                          Upload a personalized image for your podcast - 1000 x
                          1000 px
                        </p>
                          <button style="display:block; height:35px;margin-bottom:10px" class="fileInput1"  @click.prevent="$refs.fileInput1.click()">اضغط هنا لتحميل صورة  </button>
                          <input type='file' style="display:none" ref="fileInput1" multiple  @change="onfileSelected">
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Pick a name for your podcast*</h3>
                        <p>Pick a unique display name</p>
                        <el-input
                          v-model="form.title"
                          placeholder="Podcast Name"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                  ><div class="grid-content">
                    <el-form-item>
                      <h3>Description*</h3>
                      <p>Write a few lines about your podcast</p>
                      <el-input
                      :rows="6"
                        type="textarea"
                        placeholder="Short description of your podcast. Few sentences are recommended"
                        v-model="form.description"
                      ></el-input>
                    </el-form-item></div
                ></el-col>


                                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Creator’s Name *</h3>
                        <p>Who is the creator of this podcast</p>
                        <el-input
                          v-model="form.name"
                          placeholder="placeholder"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>


                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Copyright</h3>

                        <el-input
                          v-model="form.copyright"
                          placeholder="Copywriter’s Name"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>


                </el-row>
                <!-- <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Explicit</h3>
                        <p>Warn listeners about language or sexual content</p>
                        <el-switch
                          style="display: block"
                          v-model="value2"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Yas"
                          inactive-text="No"
                        >
                        </el-switch>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Type*</h3>
                        <p>What type of podcast you're adding?*</p>
                        <el-switch
                          style="display: block"
                          v-model="value2"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text=" Episodic "
                          inactive-text=" Serial"
                        >
                        </el-switch>
                      </el-form-item></div
                  ></el-col>
                </el-row> -->
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Main category*</h3>
                        <p>Select the main category your podcast falls under</p>
                        <el-select v-model="form.category_id" placeholder="Category"  @change="allSup">
                          <el-option
                            v-for="item in all_category"
                            :key="item"
                            :label="item.title"
                            :value="item.id"

                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Secondary category*</h3>
                        <p>
                          Select as many subcategories as you feel appropriate
                        </p>
                        <el-select
                          v-model="form.sub_categories"
                          placeholder=" Select as many subcategories as you feel appropriate"
                        >
                          <el-option
                            v-for="item in all_sub_category"
                            :key="item.id"
                            :label="item.title"
                            :value="item.title"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Discovery</h3>
                        <p>Customize your podcast for a specific audience</p>

                        <h3>Tags</h3>
                        <p>Add tags so people would find your podcast</p>
                        <!-- <el-input
                          v-model="form.tags"
                          placeholder="Tags:#example #best #sports"
                        ></el-input> -->

                         <input-tag
                          v-model="form.tags"
                          placeholder="Tags:#example #best #sports"
                          add-tag-on-keys="13"
                          class="blogInput"
                        >
              </input-tag>
                      </el-form-item></div
                  ></el-col>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Language*</h3>
                        <p>Select the language of your podcast</p>
                        <el-select
                          v-model="form.lang"
                          placeholder="Select the language of your podcast"
                        >
                          <el-option
                            v-for="(item,key) in all_langs"
                            :key="item"
                            :label="item"
                            :value="key"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Country</h3>
                        <p>Select your podcast's country of origin</p>
                        <el-select
                          v-model="form.country"
                          placeholder="Select your podcast's country of origin"
                        >
                          <el-option
                            v-for="(item,key) in all_countries"
                            :key="item"
                            :label="item"
                            :value="key"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Website</h3>
                        <p>Add your podcast's website</p>
                        <el-input
                          v-model="form.website"
                          placeholder="Website’s url"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>



                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button round>Cancle</el-button>
                      </el-form-item>
                    </div></el-col
                  >

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button type="primary" @click.prevent="create" round>Update</el-button>
                      </el-form-item>
                    </div></el-col
                  >
                </el-row>

                <!--
                <el-col :span="12"><div class="grid-content"></div></el-col>

                <el-col :span="12"><div class="grid-content"></div></el-col> -->
              </el-form>
            </div>

        </el-tab-pane>
      </el-tabs>
    </div>


         </div
      ></el-col>



    </el-row>



  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
// import PieChart from "@/components/pie.vue";

import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "HomeView",
  components: { Pie },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },



    // val1:'',
    val2: "",
  },

  data() {
    return {
      acc:undefined,
      user:undefined,

      userAuth:undefined,

      show:true,
      value2: false,

      value1: "",

      form: [
        {
          url: "",
          name:'',
          title:"",
          description:"",
          copyright:"",
          category_id:"",
          sub_categories:"",
          tags:"",
          country:"",
          lang:"",
          website:"",
          date:""
        },
      ],

      imageSelcteed: null,

      all_langs: undefined,
      all_category: undefined,
      all_countries: undefined,
      all_sub_category: undefined,


      activeIndex: "1",

      id: undefined,
      main_id:undefined,
      analysis_podcast: undefined,
      uniqe_view: undefined,
      total_view: undefined,
      activeName: "first",
      form: [
        {
          url: "",
        },
      ],

      chartData: {
        labels: ["Uniqe View", "Total View"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [50, 50],
          },
        ],
      },

          chartData2: {
        labels: ["Male", "Female"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [50, 50],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      activeIndex: "1",
      my_Episodes: undefined,
      sound_item: undefined,
      count_item: undefined,
    };
  },

  mounted() {
    const id = this.$route.params.code;
    this.id = this.$route.params.code;
    this.axios
      .get(
        `http://podcasts.arabicreators.com/api/single_podcast/${id}?ip=554854236`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        localStorage.setItem("podcastCode", res.data.data.code);

        this.my_Episodes = res.data.data;
        this.main_id=res.data.data.id


        this.sound_item = res.data.data.sound_item;
        this.count_item = res.data.data.count_item;
        // alert(this.count_item)
      });


    this.axios
      .get(
        `http://podcasts.arabicreators.com/api/analysis_podcast/${this.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.analysis_podcast = res.data.data;
        this.uniqe_view = res.data.data.uniqe_view;
        this.total_view = res.data.data.total_view;

        this.chartData.datasets[0].data[0] = this.uniqe_view;
        this.chartData.datasets[0].data[1] = this.total_view;
      });



                this.form.name = localStorage.getItem('userName');

        this.axios
      .get("http://podcasts.arabicreators.com/api/all_langs")
      .then((res) => {
        console.log(res);
        this.all_langs = res.data.data;
      });

              this.axios
      .get("http://podcasts.arabicreators.com/api/all_category")
      .then((res) => {
        console.log(res);
        this.all_category = res.data.data;
      });

              this.axios
      .get("http://podcasts.arabicreators.com/api/all_countries")
      .then((res) => {
        console.log(res);
        this.all_countries = res.data.data;
      });


         console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)

      } else if (!this.user) {
        localStorage.removeItem("user")
      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
      } else if (!localStorage.getItem('user')) {
        this.userAuth = null
      }




      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }


        this.show=!this.show


    }))

  },

    methods: {


    allSup(){
      this.axios
      .get(`http://podcasts.arabicreators.com/api/all_sub_category/${this.form.category_id}`)
      .then((res) => {
        console.log(res);
        this.all_sub_category = res.data.data;
      });
    },

    onfileSelected(event) {
      this.imageSelcteed = event.target.files[0];
    },
    create(){
            const dataForm = new FormData();
                  dataForm.append("title", this.form.title);
                  dataForm.append("image",this.imageSelcteed,this.imageSelcteed.name);

                  dataForm.append("description", this.form.description);
                  dataForm.append("copyright", this.form.copyright);
                  // dataForm.append("category_id", this.form.category_id);
                  dataForm.append("sub_categories", this.form.sub_categories);
                  dataForm.append("tags", this.form.tags);
                  dataForm.append("country", this.form.country);
                  dataForm.append("lang", this.form.lang);
                  dataForm.append("website", this.form.website);
                  // dataForm.append("name", this.form.name);
                  // dataForm.append("date", this.form.date);




        this.axios
        .post(`http://podcasts.arabicreators.com/api/update_podcast/${this.main_id}`, dataForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })

        .then((res) => {
          console.log(res);

          localStorage.setItem('podcastCode',res.data.message.code)

          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تمت الاضافة بنجاح",
              type: "success",
            });


          } else {
            this.$message.error("حدث خطأ ما");
          }
        });


    }
  },
};
</script>


<style lang="scss">
.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.podcast-main::-webkit-scrollbar {
  display: none;
}

.podcast-main {
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 90%;
  margin-top: 20px;
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 40px;
  height: 485px;
  overflow-x: hidden;
  overflow-y: scroll;
  .podcast-head {
    display: flex;
    justify-content: space-between;

    .podacst-info {
      display: flex;

      .img-box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .pod-txt {
        text-align: left;
        margin-left: 5px;
        h3 {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0px;
          margin-top: 5px;
        }

        h4 {
          font-size: 16px;
          margin-bottom: 0px;
          margin-top: 5px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .add-Episode {
      a {
        background-color: #113454;
        color: white !important;
        border-radius: 22px;
        padding: 7px 18px;
        font-size: 15px;
        text-transform: capitalize;
      }
    }
  }
  .Summary {
    text-align: left;
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    p {
    }
  }

  .Episodes {
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      h3 {
      }

      a {
      }
    }

    .Episode-item {
      background-color: #ededed;
      padding: 5px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-bottom: 20px;
      .img-box {
        width: 70px;
        img {
          width: 100%;
        }
      }

      .inner-item {
        flex-grow: 1;
        text-align: left;

        h4 {
          margin-top: 5px;
          margin-bottom: -4px;
          margin-left: 20px;
        }
      }

      .vue-plyr {
      }

      .plyr--audio .plyr__controls {
        background: transparent !important;
      }

      .fa-ellipsis-vertical {
        flex-grow: 0.1;
        cursor: pointer;
      }
    }
  }
}

.Analytics{
  text-align: left;
}

.content-Insights{
  height: 465px;
  overflow-x: hidden;
  overflow-y: scroll;

}

.content-Insights::-webkit-scrollbar {
  display: none;
}
</style>
