import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import InputTag from 'vue-input-tag'

// import Bar  from 'vue-chartjs'

import AudioRecorder from 'vue-audio-recorder'

Vue.use(AudioRecorder)

Vue.use(VueAxios, axios)


Vue.use(ElementUI, { locale });

Vue.component('input-tag', InputTag)

Vue.use(VuePlyr, {
  plyr: {}
})









Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
