<template>
  <div class="register">


   <div class="inner-page">
      <el-row>
        <el-col :span="12" style="text-align:left;">

  <img class="logo" src="../assets/logos/logo.png" alt="">
  <h2>Create Manage Distribute <span>Podcasts</span></h2>
      <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
     <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button>

        </el-col>

        <el-col :span="12">
            <div class="img-box">
              <img src="../assets/pod.png" alt="">
            </div>
        </el-col>
      </el-row>
   </div>

  <div class="wraper">

<el-dialog
  style=""
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
      <div class="register">

        <el-form ref="form" class="form" :model="form" label-width="120px" method="post"
            @submit.prevent="login">
                    <h3>Sign up</h3>
                      <p>Don't have an account yet? <router-link to="">Sign up</router-link></p>




                <el-form-item label="Email" label-width="auto" label-position="top">
                    <el-input v-model="form.email" placeholder="name@gmail.com"></el-input>
                </el-form-item>

                <el-form-item label="Password" label-width="auto" label-position="top">
                    <el-input v-model="form.password" placeholder="Password" show-password></el-input>
                </el-form-item>

<router-link to="">Forgot Password?</router-link>
                 <el-form-item>
    <el-button type="primary" @click.prevent="login">Sign up</el-button>
  </el-form-item>
        </el-form>



    </div>

</el-dialog>
  </div>



<div class="wraper">
  <el-dialog
  :visible.sync="dialogVisible2"
  width="30%"
  :before-close="handleClose">
    <div class="register register-height">

        <el-form ref="form" class="form" :model="form" label-width="120px" method="post"
            @submit.prevent="Register">
                    <h3>Sign up</h3>
                    <p>Already have an account? <router-link to="">Sign in</router-link></p>

                <el-form-item label="Name" label-width="auto" label-position="top">
                    <el-input v-model="form.name" placeholder="Name"></el-input>
                </el-form-item>

                <el-form-item label="Full name" label-width="auto" label-position="top">
                    <el-input v-model="form.full_name" placeholder="Full Name"></el-input>
                </el-form-item>


                <el-form-item label="Email" label-width="auto" label-position="top">
                    <el-input v-model="form.email" placeholder="name@gmail.com"></el-input>
                </el-form-item>

                <el-form-item label="Password" label-width="auto" label-position="top">
                    <el-input v-model="form.password" placeholder="Password" show-password></el-input>
                </el-form-item>

                <el-form-item label="Confirm Password" label-width="auto" label-position="top">
                    <el-input v-model="form.confirm_password" placeholder="Confirm Password" show-password></el-input>
                </el-form-item>
                  <el-checkbox v-model="checked">I agree to the <router-link to="">Terms of Service</router-link> and <router-link to="">Privacy Policy</router-link> </el-checkbox>


                 <el-form-item>
    <el-button type="primary" @click.prevent="Register">Sign up</el-button>
  </el-form-item>
        </el-form>



    </div>

</el-dialog>
</div>

  </div>
</template>



<script>
export default {
  data() {
    return {
            dialogVisible:false,
      dialogVisible2:false,
      arrMsg: [],
      arrMsg2: [],
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },

      async login(){
               const res=  await this.axios.post("http://podcasts.arabicreators.com/api/login",this.form).then((r=>{


                  this.arrMsg=[]


        if(r.data.code==400){

          // alert(this.arrMsg.length)

          if(Array.isArray(r.data.message)){


          for (let index = 0; index < r.data.message.length; index++) {
              this.arrMsg.push(r.data.message[index])
          }

          //  alert(this.arrMsg.length)


          for (let i = 0; i < this.arrMsg.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg[i]),


             1000)

          }

           }

        }

        else if(r.data.code==200){
          this.$message({
          message: 'تم تسجيل الدخول بنجاح',
          type: 'success'
        });

          this.dialogVisible = false
                    this.$router.push('/')

          //  window.scrollTo(0,0);

            localStorage.setItem('token',r.data.data.token)


        }

            }))

      },


            async Register(){
                        const res=  await this.axios.post("http://podcasts.arabicreators.com/api/register",this.form).then((r=>{

                                                    this.arrMsg2=[]


      if(r.data.code== 400){

           if(Array.isArray(r.data.message)){

          // alert(this.arrMsg.length)

          for (let index = 0; index < r.data.message.length; index++) {
              this.arrMsg2.push(r.data.message[index])
          }

          //  alert(this.arrMsg.length)
          //  alert(this.arrMsg[1])

          for (let i = 0; i < this.arrMsg2.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg2[i]),


             1000)

          }

          }

      }


       else if(r.data.code== 200){
        this.$message({
          message: 'تم التسجيل بنجاح',
          type: 'success'
        });
          localStorage.setItem('token',r.data.data.token)
          localStorage.setItem('userEmail',this.form.email)

          this.dialogVisible2 = false
          // this.$router.go()

          this.$router.push('/')




  }

            }))

      },

      logOut() {
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$router.go();
      this.$message("تم تسجيل الخروج");
    },

  },
};
</script>


<style lang="scss">
.register {
  position: relative;

  .form {
    padding: 20px;
    width: 30%;
    margin: auto;
    position: absolute;
    right: 50%;
    transform: translateX(200px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;

    .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-button {
      width: 100%;
      margin-top: 30px;
    }
  }
}
</style>



<style lang="scss">

    .register{

        position: relative;


    .form{
    padding: 20px;
    width: 90% !important;
    margin: auto;
    position: absolute;
    right: 50%;
    transform: translateX(200px);
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: none !important;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
    text-align: left;
    margin-top: 0 !important;

        .el-form-item__content{
            margin-left: 0 !important;
        }

    .el-button{
    width: 100%;
    margin-top: 30px;
    background: #97a6ba61;
    border: navajowhite;
    border-radius: 25px;
    font-weight: 600;
    font-size: 20px;

    }

    .el-form-item__label,.el-checkbox__label{
      color: white;
    }

    p{
      color: white;
    }

    .el-input__inner{
      border-color: white;
      background: transparent !important ;
      color: white;
      height: 45px;
      border-radius: 8px;
      border-color: #409eff;
    }

    h3{
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    }

    a{
      color: #409eff !important;
    }

    .el-form-item{
      margin-bottom: 12px;
    }


    }



    }

.wraper{
.el-dialog{
  background: #113454 !important;
  color: white;
  border-radius: 8px !important;
}


.el-dialog__wrapper{
  top: -100px !important;
}

.el-dialog__body{
     height: 500px !important;
    overflow-Y: scroll !important;
    overflow-x: hidden !important;
}

.el-dialog__body::-webkit-scrollbar {
    display: none !important;
}


 .register-height .el-dialog__body{
  height: 600px !important;
}
}






.inner-page{
    height: 100vh;
    background-image: url(http://10.0.0.5:8080/img/landing.b10a8184.png);
    background-color: #2d303c;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

  .logo{
    margin-right: auto;
  }

  h2{
    color: white;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 15px;
    text-transform: capitalize;
    text-align: left;

    span{
      color: #409eff;
      font-size: 55px;
    }
  }

      .el-button{
    width: 38%;
    float: left;
    background: #409eff;
    border: navajowhite;
    border-radius: 28px;
    font-weight: 600;
    font-size: 20px;
    color: white;
    height: 55px;

    }

    .el-button--primary{
      background: transparent;
      border: 2px solid #409eff;
      color: #409eff;
    }



  .img-box{
      width: 500px;
    img{
      width: 100%;
    }
  }
}




</style>
