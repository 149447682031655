import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import add from '../views/add.vue'
import addEpisodes from '../views/addEpisodes.vue'
import addAudio from '../views/addAudio.vue'
import podcastInner from '../views/podcastInner.vue'
import analytics from '../views/analytics.vue'
import Settings from '../views/Settings.vue'




import register from '../views/register.vue'
import login from '../views/login.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/add',
    name: 'add',
    component: add
  },

  {
    path: '/addEpisodes',
    name: 'addEpisodes',
    component: addEpisodes
  },

    

  {
    path: '/addAudio',
    name: 'addAudio',
    component: addAudio
  },

  {
    path: '/podcast/:code',
    name: 'podcastInner',
    component: podcastInner
  },

  {
    path: '/analytics',
    name: 'analytics',
    component: analytics
  },


  {
    path: '/Settings',
    name: 'Settings',
    component: Settings
  },

  // {
  //   path: '/register',
  //   name: 'register',
  //   component: register
  // },

  {
    path: '/login',
    name: 'login',
    component: login
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
