<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->



          <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <!-- <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button> -->

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>



    <div class="main-content">



      <table>
        <thead>
          <tr>
            <td></td>
            <td>Name</td>
            <td>DateCreated</td>
            <td>Episodes</td>
            <td>Status</td>
            <td>Listens</td>
            <td></td>

          </tr>
        </thead>
        <tbody>
          <tr  v-for="item in my_podcast" :key="item.id" @click="goInner(item.code)">
            <td class="column"> <img  :src="item.image" width="40px" height="40px" alt=""></td>
            <td class="column">{{item.title}}</td>
            <td class="column">{{item.publish_date}}</td>
            <td class="column">{{item.count_item}}</td>
            <td class="column">Published</td>
            <td class="column"></td>
            <td><el-dropdown @command="deletePod(item.id)">
                    <span class="el-dropdown-link">
                      <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" >
                      <el-dropdown-item command="a">Delate</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown></td>
          </tr>
        </tbody>

      </table>

              <div class="" style="margin-top:15px" v-if="my_podcast.length == 0">
                <img src="../assets/empty-folder.png" alt="">
                <p style="margin-auto;text-align:center">There are no podcasts</p>

              </div>










      <!-- <el-table :data="my_podcast" height="250" style="width: 100%">
              <el-table-column  width="180">
                <img  prop="image" width="40px" height="40px" alt="">
              </el-table-column>
              <el-table-column prop="title" label="Name" width="180">
              </el-table-column>
              <el-table-column
                prop="description"
                label="Date Created"
                width="180"
              >
              </el-table-column>

              <el-table-column prop="Status" label="Status" width="180">
              </el-table-column>
              <el-table-column prop="Listens" label="Listens" width="180">
              </el-table-column>

              <el-table-column fixed="right" label="Operations" width="120">
                <template slot-scope="scope">

                </template>
              </el-table-column>
            </el-table> -->

      <el-button class="createPodcast" @click="dialogVisible = true"
        ><i class="el-icon-microphone"></i> Create Podcast</el-button
      >

      <el-dialog
        title="Create Podcast"
        :visible.sync="dialogVisible"
        width="70%"
        :before-close="handleClose"
      >
        <el-row>
          <el-col :span="11"
            ><div class="grid-content">
              <el-button type="info"> Start your Podcast </el-button>
              <p>Don't have a Podcast?</p>
              <h3>START HERE</h3>
              <div class="img-box">
                <img src="../assets/upload.png" alt="" />
              </div>

              <el-button type="primary" round
                ><router-link to="/add"
                  ><i class="el-icon-upload2"></i> Start your
                  Podcast</router-link
                >
              </el-button>
            </div>
          </el-col>

          <el-col :span="11"
            ><div class="grid-content">
              <el-button type="info"> Import Podcast </el-button>
              <p>Already have a Podcast?</p>
              <h3>IMPORT HERE</h3>
              <div class="img-box">
                <img src="../assets/cloud-computing.png" alt="" />
              </div>

              <el-button
                type="primary"
                @click="
                  dialogVisible = false;
                  dialogVisible2 = true;
                "
                round
                ><i class="el-icon-download"></i> Import your media file
              </el-button>
            </div></el-col
          >
        </el-row>
      </el-dialog>

      <el-dialog
        title="Import Podcast"
        :visible.sync="dialogVisible2"
        width="60%"
        :before-close="handleClose"
      >
        <!-- <h5>PODCAST RSS LINK</h5> -->
        <el-form ref="form" :model="form" method="post"
            @submit.prevent="GetRss">
          <el-form-item>
            <el-input
              v-model="import_rss"
              placeholder="Enter Podcast Rss Link"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: center !important">
            <el-button @click.prevent="GetRss" type="primary" round
              ><i class="el-icon-download"></i> Import your media
              file</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>


         </div
      ></el-col>



    </el-row>




  </div>
</template>

<script>
// @ is an alias to /src
// import BarChart from 'components/BarChart.vue'

import BarChart from "@/components/BarChart.vue";


export default {
  name: "HomeView",
  components: {BarChart},
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,

      form: [
        {
          url: "",
        },
      ],

      import_rss:'',
      tableData: [
        {
          Name: "Tom",
          DateCreated: "2022-05-03",
          Episodes: "2",
          Status: "open",
          Listens: "no data",
        },
      ],
      activeIndex: "1",

      my_podcast: undefined,

            acc:undefined,
      user:undefined,

      userAuth:undefined,

    };
  },

  mounted() {
    console.log(localStorage.getItem("token"));

    this.axios
      .get("http://podcasts.arabicreators.com/api/my_podcast", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        this.my_podcast = res.data.data;
      });

            console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)



      } else if (!this.user) {
        localStorage.removeItem("user")



      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
                      this.$router.push(`/`);

      } else if (!localStorage.getItem('user')) {
        this.userAuth = null


      }


      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }



        if(r.data.message=='you need to login'){
        this.$router.push(`/login`);

        }


        // this.show=!this.show


    }))


  //    if(this.userAuth) {
  //   }

  //  else if (! this.userAuth){
  //  }



  },

  methods: {
    goInner(code){
    this.$router.push(`/podcast/${code}`);

    },

          logOut() {
      localStorage.removeItem("token");
      this.$router.push("/login");
      this.$router.go();
      this.$message("تم تسجيل الخروج");
    },

    GetRss(){

         const dataRSS = new FormData();
         dataRSS.append("url", this.import_rss);

        this.axios
        .post(`http://podcasts.arabicreators.com/api/import_rss`, dataRSS, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })

        .then((res) => {
          console.log(res);

          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تمت الاضافة بنجاح",
              type: "success",
            });

          } else {
            this.$message.error("حدث خطأ ما");
          }
        });
    },

    callback (msg) {
      console.debug('Event: ', msg)
    },

    deletePod(podId){
      this.axios.delete(`http://podcasts.arabicreators.com/api/delete_podcast/${podId}`,{
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
      })
      .then((res=>{
                  if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تم الحذف بنجاح",
              type: "success",
            });

            this.$router.go()

          } else {
            this.$message.error("حدث خطأ ما");
          }
      }))
    }


  },
};
</script>


<style lang="scss">
body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}

.home {
  font-family: "Poppins", sans-serif;
}

.el-menu {
  background: transparent !important;
  border-right: none !important;
}

.el-menu-item i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.el-col-4 {
  height: 100vh !important;
}

.aisad {
  background-color: #113454;
  height: 100% !important;
  // padding: 20px;
  color: white;

  .img-logo {
    width: 145px;
    height: 40px;
    padding-left: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-item {
  text-align: start;
  font-size: 16px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #161c33 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  i {
    font-size: 25px !important;
  }

  .userInfo {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #444;
      font-weight: 600;
    }
  }

  .user-image {
    .img-box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin-top: 80px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

table {
  width: 100%;

  thead {
    tr {
      box-shadow: 0 6px 4px -4px #00000029;
      background-color: #fafafa;
      td {
        padding: 10px !important;
      }
    }
  }

    tr {
      box-shadow: 0 6px 4px -4px #00000029;
      cursor: pointer;
      td {
        padding: 8px !important;

      }

      .column{
        border: none !important;
      }
    }

    .fa-ellipsis-vertical{
      color: rgb(182, 182, 182);
    }
}

.el-row{
  margin-bottom: 0 !important;
}


.content{
  border-radius: 15px;
  margin: 15px;
  margin-top: 30px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  t
  .top{
      border-radius: 15px;
  margin: 10px;
  margin-top: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


  .border-right{
      border-right: 1px solid #989898;
  }

  .view{
    text-align: left;
    padding: 10px;

    .viewTop{
      display: flex;
      align-items: center;
      justify-content: space-between;
    h3{

    }
    }



    span{
      font-weight: 700;
      font-size: 22px;
    }
  }

  }

  .view-Insights{
  border-radius: 15px;
  margin: 10px;
  margin-top: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  h3{
    text-align: left;
  }
  }
}


</style>
