<template>

    <div class="a">
                <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <!-- <img src="../assets/logos/logo.png" alt="" /> -->
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1">
                  <i class="el-icon-mic"></i>
                    <router-link to="/"><span>Podcasts</span></router-link>
                </el-menu-item>

                <el-menu-item index="2">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">
            <!-- <i class="el-icon-s-unfold"></i> -->


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button>

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>

              <!-- <div class="user-image" v-if="userAuth">
                <a href="">
                  <div class="img-box">
                    <img v-if="acc && acc.image" :src="acc.image" alt="" />
                  </div>
                </a>
              </div> -->

 <!-- <a class="dropdown-item" @click.prevent="logOut" href="#"
                  ></a
                > -->

  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>


              <router-view />


         </div
      ></el-col>



    </el-row>
    </div>
</template>



<script>

export default {
  name: 'BarChart',
  components: { },
  data() {
    return {
      acc:undefined,
      user:undefined,

      userAuth:undefined,

      show:true,

      dialogVisible:false,
      dialogVisible2:false,


        arrMsg:[],
        form: {
          email: '',
          password: '',

        }
  }},

  mounted() {
      console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)

      } else if (!this.user) {
        localStorage.removeItem("user")
      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
      } else if (!localStorage.getItem('user')) {
        this.userAuth = null
      }




      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }


        this.show=!this.show


    }))
},

methods: {
              async login(){
               const res=  await this.axios.post("http://podcasts.arabicreators.com/api/login",this.form).then((r=>{


                  this.arrMsg=[]


        if(r.data.code==400){

          // alert(this.arrMsg.length)

          if(Array.isArray(r.data.message)){


          for (let index = 0; index < r.data.message.length; index++) {
              this.arrMsg.push(r.data.message[index])
          }

          //  alert(this.arrMsg.length)
          //  alert(this.arrMsg[1])

          for (let i = 0; i < this.arrMsg.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg[i]),


             1000)

          }

           }

        }

        else if(r.data.code==200){
          this.$message({
          message: 'تم تسجيل الدخول بنجاح',
          type: 'success'
        });

          this.dialogVisible = false
          this.$router.go()
          //  window.scrollTo(0,0);

            localStorage.setItem('token',r.data.data.token)


        }

            }))

      },


            async Register(){
                        const res=  await this.axios.post("http://podcasts.arabicreators.com/api/register",this.form).then((r=>{

                                                    this.arrMsg=[]


      if(r.data.code== 400){

           if(Array.isArray(r.data.message)){

          // alert(this.arrMsg.length)

          for (let index = 0; index < r.data.message.length; index++) {
              this.arrMsg.push(r.data.message[index])
          }

          //  alert(this.arrMsg.length)
          //  alert(this.arrMsg[1])

          for (let i = 0; i < this.arrMsg.length; i++) {


             setTimeout(()=>

                 this.$message.error(this.arrMsg[i]),


             1000)

          }

          }

      }


       else if(r.data.code== 200){
        this.$message({
          message: 'تم التسجيل بنجاح',
          type: 'success'
        });
          localStorage.setItem('token',r.data.data.token)
          localStorage.setItem('userEmail',this.form.email)

          this.dialogVisible2 = false
          this.$router.go()

          // this.$router.push('/')




  }

            }))

      },

      logOut() {
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$router.go();
      this.$message("تم تسجيل الخروج");
    },
},


}
</script>



<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>



<style lang="scss">
body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}

.home {
  font-family: "Poppins", sans-serif;
}

.el-menu {
  background: transparent !important;
  border-right: none !important;
}

.el-menu-item i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.el-col-4 {
  height: 100vh !important;
}

.aisad {
  background-color: #113454;
  height: 100% !important;
  // padding: 20px;
  color: white;

  .img-logo {
    width: 145px;
    height: 40px;
    padding-left: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-item {
  text-align: start;
  font-size: 16px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #161c33 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  i {
    font-size: 25px !important;
  }

  .userInfo {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #444;
      font-weight: 600;
    }
  }

  .user-image {
    .img-box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}


.loader{
      position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    z-index: 5;
    align-items: center;

    .spinner-inner{
      transform: scale(.8) !important;
    }
}
</style>
