
<template>


  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
    </nav> -->


        

        <div class="loader" v-if="show">
          <Circle9></Circle9>
        </div>

              <router-view />

          <!-- <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="./assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button>

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>




         </div
      ></el-col>



    </el-row> -->


    <!-- <el-button type="text" >click to open the Dialog</el-button> -->




  </div>
</template>


<script>
import {Circle9} from 'vue-loading-spinner'

export default {
      components: {
      Circle9
    },
  data() {
    return {
      acc:undefined,
      user:undefined,

      userAuth:undefined,

      show:true,

      dialogVisible:false,
      dialogVisible2:false,


        arrMsg:[],
        form: {
          email: '',
          password: '',

        }

    }
  },

mounted() {
      console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)

      } else if (!this.user) {
        localStorage.removeItem("user")
      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
      } else if (!localStorage.getItem('user')) {
        this.userAuth = null
      }




      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }


        this.show=!this.show


    }))
},

methods: {
  //             async login(){
  //              const res=  await this.axios.post("http://podcasts.arabicreators.com/api/login",this.form).then((r=>{


  //                 this.arrMsg=[]


  //       if(r.data.code==400){

  //         // alert(this.arrMsg.length)

  //         if(Array.isArray(r.data.message)){


  //         for (let index = 0; index < r.data.message.length; index++) {
  //             this.arrMsg.push(r.data.message[index])
  //         }

  //         //  alert(this.arrMsg.length)
  //         //  alert(this.arrMsg[1])

  //         for (let i = 0; i < this.arrMsg.length; i++) {


  //            setTimeout(()=>

  //                this.$message.error(this.arrMsg[i]),


  //            1000)

  //         }

  //          }

  //       }

  //       else if(r.data.code==200){
  //         this.$message({
  //         message: 'تم تسجيل الدخول بنجاح',
  //         type: 'success'
  //       });

  //         this.dialogVisible = false
  //         this.$router.go()
  //         //  window.scrollTo(0,0);

  //           localStorage.setItem('token',r.data.data.token)


  //       }

  //           }))

  //     },


  //           async Register(){
  //                       const res=  await this.axios.post("http://podcasts.arabicreators.com/api/register",this.form).then((r=>{

  //                                                   this.arrMsg=[]


  //     if(r.data.code== 400){

  //          if(Array.isArray(r.data.message)){

  //         // alert(this.arrMsg.length)

  //         for (let index = 0; index < r.data.message.length; index++) {
  //             this.arrMsg.push(r.data.message[index])
  //         }

  //         //  alert(this.arrMsg.length)
  //         //  alert(this.arrMsg[1])

  //         for (let i = 0; i < this.arrMsg.length; i++) {


  //            setTimeout(()=>

  //                this.$message.error(this.arrMsg[i]),


  //            1000)

  //         }

  //         }

  //     }


  //      else if(r.data.code== 200){
  //       this.$message({
  //         message: 'تم التسجيل بنجاح',
  //         type: 'success'
  //       });
  //         localStorage.setItem('token',r.data.data.token)
  //         localStorage.setItem('userEmail',this.form.email)

  //         this.dialogVisible2 = false
  //         this.$router.go()

  //         // this.$router.push('/')




  // }

  //           }))

  //     },

      logOut() {
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$router.go();
      this.$message("تم تسجيل الخروج");
    },
},
}




</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>



<style lang="scss">
body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}

.home {
  font-family: "Poppins", sans-serif;
}

.el-menu {
  background: transparent !important;
  border-right: none !important;
}

.el-menu-item i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.el-col-4 {
  height: 100vh !important;
}

.aisad {
  background-color: #113454;
  height: 100% !important;
  // padding: 20px;
  color: white;

  .img-logo {
    width: 145px;
    height: 40px;
    padding-left: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-item {
  text-align: start;
  font-size: 16px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #161c33 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  i {
    font-size: 25px !important;
  }

  .userInfo {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #444;
      font-weight: 600;
    }
  }

  .user-image {
    .img-box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}


.loader{
      position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    z-index: 5;
    align-items: center;

    .spinner-inner{
      transform: scale(.8) !important;
    }
}
</style>


<style lang="scss">

    .register{

        position: relative;


    .form{
    padding: 20px;
    width: 90% !important;
    margin: auto;
    position: absolute;
    right: 50%;
    transform: translateX(200px);
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: none !important;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
    text-align: left;
    margin-top: 0 !important;

        .el-form-item__content{
            margin-left: 0 !important;
        }

    .el-button{
    width: 100%;
    margin-top: 30px;
    background: #97a6ba61;
    border: navajowhite;
    border-radius: 25px;
    font-weight: 600;
    font-size: 20px;

    }

    .el-form-item__label,.el-checkbox__label{
      color: white;
    }

    p{
      color: white;
    }

    .el-input__inner{
      border-color: white;
      background: transparent !important ;
      color: white;
      height: 45px;
      border-radius: 8px;
      border-color: #409eff;
    }

    h3{
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    }

    a{
      color: #409eff !important;
    }

    .el-form-item{
      margin-bottom: 12px;
    }


    }



    }

.wraper{
.el-dialog{
  background: #113454 !important;
  color: white;
  border-radius: 8px !important;
}


.el-dialog__wrapper{
  top: -100px !important;
}

.el-dialog__body{
     height: 500px !important;
    overflow-Y: scroll !important;
    overflow-x: hidden !important;
}

.el-dialog__body::-webkit-scrollbar {
    display: none !important;
}


 .register-height .el-dialog__body{
  height: 600px !important;
}
}





</style>
