<template>
  <div class="analytics">


    <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">
               <!-- <el-button v-if="! userAuth" @click="dialogVisible = true"><router-link to="">Sign in</router-link></el-button>
              <el-button v-if="! userAuth" @click="dialogVisible2 = true" type="primary"><router-link to="">Sign up</router-link></el-button> -->

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>

            <div class="main-content">
        <h3 style="text-align:left;margin-left:15px;margin-bottom:5px;">Profile Settings</h3>
        <p style="text-align:left;margin-left:15px;margin-top:5px;" >Enter below information about yourself.</p>
          <div class="content">

                          <el-form ref="form" :model="form" label-width="120px" method="post"
            @submit.prevent="create">
                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3>Add Profile Picture</h3>
                        <p>
                         Upload your photo


                        </p>
                          <button style="display:block; height:35px;margin-bottom:10px" class="fileInput1"  @click.prevent="$refs.fileInput1.click()">اضغط هنا لتحميل صورة  </button>
                          <input type='file' style="display:none" ref="fileInput1" multiple  @change="onfileSelected">
                      </el-form-item></div
                  ></el-col>

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <h3> Pick your display name* </h3>
                        <p>Pick a unique display name.</p>
                        <el-input
                          v-model="form.name"
                          placeholder="Name"
                        ></el-input>
                      </el-form-item></div
                  ></el-col>
                </el-row>

                <el-row>
                  <el-col :span="12"
                  ><div class="grid-content">
                    <el-form-item>
                      <h3>About me</h3>
                      <p>Share your story in a few lines</p>
                      <el-input
                      :rows="6"
                        type="textarea"
                        placeholder="Description"
                        v-model="form.description"
                      ></el-input>
                    </el-form-item></div
                ></el-col>

                </el-row>

                <el-row>


                   <el-col :span="12"
                    >


                    <el-row>
                         <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item>
                        <h3>Old Password</h3>
                        <el-input
                          v-model="form.old_passWord"
                          placeholder="*******"
                        ></el-input>
                      </el-form-item></div
                  >
                  </el-col>

                                    <el-col :span="12">
                    <div class="grid-content">
                      <el-form-item>
                        <h3>New Password</h3>
                        <el-input
                          v-model="form.new_passWord"
                          placeholder="*******"
                        ></el-input>
                      </el-form-item></div
                  >
                  </el-col>
                    </el-row>


                    </el-col>
                </el-row>






                <el-row>
                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button round>Cancle</el-button>
                      </el-form-item>
                    </div></el-col
                  >

                  <el-col :span="12"
                    ><div class="grid-content">
                      <el-form-item>
                        <el-button type="primary" @click.prevent="create" round>Save</el-button>
                      </el-form-item>
                    </div></el-col
                  >
                </el-row>

                <!--
                <el-col :span="12"><div class="grid-content"></div></el-col>

                <el-col :span="12"><div class="grid-content"></div></el-col> -->
              </el-form>

          </div>


    </div>


         </div
      ></el-col>



    </el-row>


  </div>
</template>

<script>
// @ is an alias to /src
// import BarChart from 'components/BarChart.vue'



export default {
  name: "HomeView",
  components: {},

  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,

       imageSelcteed: null,
      form: [
        {
          name:'',
          description:"",
          new_passWord:"",
          old_passWord:"",

        },
      ],



      import_rss:'',
      tableData: [
        {
          Name: "Tom",
          DateCreated: "2022-05-03",
          Episodes: "2",
          Status: "open",
          Listens: "no data",
        },
      ],
      activeIndex: "1",

      my_podcast: undefined,
    };
  },

  mounted() {


  },

  methods: {

    onfileSelected(event) {
      this.imageSelcteed = event.target.files[0];
    },

        create(){
            const dataForm = new FormData();
                  dataForm.append("full_name", this.form.name);
                  dataForm.append("image",this.imageSelcteed,this.imageSelcteed.name);
                  dataForm.append("pio", this.form.description);
                  dataForm.append("new_password", this.form.new_passWord);
                  dataForm.append("old_password", this.form.old_passWord);





        this.axios
        .post("http://podcasts.arabicreators.com/api/edit_profile", dataForm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })

        .then((res) => {
          console.log(res);

          localStorage.setItem('podcastCode',res.data.message.code)

          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "تم التعديل بنجاح",
              type: "success",
            });


         this.$router.go()

          } else {
            this.$message.error("حدث خطأ ما");
          }
        });


    }

  },
};
</script>


<style lang="scss">


.main-content::-webkit-scrollbar {
  display: none;
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
    height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.main-content {
  .inner-content {
    padding: 20px;
    margin: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 5px;
  }
}

.el-form-item {
  h3 {
    margin-bottom: 2px !important;
  }

  p {
    margin: 0px;
  }
  // width: 49%;
  // float: left;
  text-align: start !important;
}

.inner-content::-webkit-scrollbar {
  display: none;
}

.el-select {
  width: 100%;
}

.el-input__inner,
.el-textarea__inner {
  background-color: #f2f2f2 !important;
}

.inner-content {
  .el-button {
    width: 100%;
  }
}

.el-steps--simple {
  margin: 20px;
}

.el-button-group {
  display: flex !important;
}

.el-button:active {
  background-color: #409eff !important;
  color: white !important;
}

.el-button:focus {
  background-color: #409eff !important;
  color: white !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}


  .el-form .el-form-item .blogInput .el-input__inner {
    text-align: right !important;
    background-color: #f2f2f2  !important;
  }

  .blogInput {
    background-color: #f2f2f2  !important;
  }

  .vue-input-tag-wrapper {
    width: 100%;
    // background-color: #fafafa;
    background-color: #f2f2f2  !important;

    border-radius: 5px;
    height: 40px;
    border: none;
  }

  .vue-input-tag-wrapper .new-tag{
    text-indent: 10px;
  }

  .vue-input-tag-wrapper .input-tag{
    background-color: #113454 !important;
    color: white !important;
    border-color: #113454 !important;
  }


.el-switch{
  margin-bottom: 20px;
}

.el-picker-panel__content tr{
  box-shadow: none !important;
}

.el-button{
  width: 100%;
}


</style>
