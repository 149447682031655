<template>
  <div class="analytics">





      <el-row>
      <el-col :span="4"
        ><div class="aisad">
          <el-row class="tac">
            <el-col :span="24">
              <div class="img-logo">
                <img src="../assets/logos/logo.png" alt="" />
              </div>
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
              >
                <el-menu-item index="1" @click="$router.push('/')">
                  <i class="el-icon-mic"></i>
                    <span>Podcasts</span>
                </el-menu-item>

                <el-menu-item index="2" @click="$router.push('/analytics')">
                  <i class="el-icon-s-data"></i>
                  <span>Analytics</span>
                </el-menu-item>

                <el-menu-item index="3" @click="$router.push('/Settings')">
                  <i class="el-icon-setting"></i>
                  <span>Settings</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div></el-col
      >

      <el-col :span="18"
        ><div class="grid-content">
          <div class="nav-bar">


            <i class="fa-solid fa-bars" style="font-size: 25px"></i>
            <div class="userInfo">

              <a href="" v-if="acc && acc.name && userAuth">{{acc.name}}</a>


  <el-dropdown @command="logOut">
  <span class="el-dropdown-link">
     <div class="img-box">
                    <img v-if="acc && acc.image" width="50px" :src="acc.image" alt="" />
                  </div>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item command="a">تسجيل الخروج</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

                  <el-submenu index="2">
    <template slot="title">Workspace</template>
    <el-menu-item index="2-1" @click.prevent="logOut">تسجيل الخروج</el-menu-item>
    <el-menu-item index="2-2">item two</el-menu-item>
    <el-menu-item index="2-3">item three</el-menu-item>

  </el-submenu>


              <div class="notifcation" v-if="userAuth">
                <i class="el-icon-message-solid"></i>
              </div>
            </div>
          </div>

    <div class="main-content">
      <h3 style="text-align: left; margin-left: 15px; margin-bottom: 5px">
        Insights Dashboard
      </h3>
      <p style="text-align: left; margin-left: 15px; margin-top: 5px">
        Monitor key metrics, identify listen trends and review your content’s
        performance
      </p>
      <div class="content">
        <div class="top">
          <el-row>
            <el-col :span="12"
              ><div class="view border-right">
                <div class="viewTop">
                  <h3>UNIQUE IMPRESSIONS</h3>
                  <el-popover
                    placement="top-end"
                    width="200"
                    trigger="hover"
                    content="this is content, this is content, this is content"
                  >
                    <i slot="reference" class="el-icon-warning"></i>
                  </el-popover>
                </div>
                <span>{{ uniqe }}</span>
              </div></el-col
            >
            <el-col :span="12"
              ><div class="view">
                <div class="viewTop">
                  <h3>TOTAL IMPRESSIONS</h3>
                  <el-popover
                    placement="top-end"
                    width="200"
                    trigger="hover"
                    content="this is content, this is content, this is content"
                  >
                    <i slot="reference" class="el-icon-warning"></i>
                  </el-popover>
                </div>
                <span>{{ total }}</span>
              </div></el-col
            >
          </el-row>
        </div>

        <el-row>
          <el-col :span="12"
            ><div class="view-Insights">
              <h3>Listens</h3>

              <Pie v-if=" chartData.datasets[0].data[0] != 40 &&  chartData.datasets[0].data[1] != 20"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :width="200"
                :height="200"
              /></div
          ></el-col>
          <el-col :span="12"
            ><div class="view-Insights">
              <h3>Gender</h3>

              <Pie v-if=" chartData2.datasets[0].data[0] != 40 &&  chartData2.datasets[0].data[1] != 60"
                :chart-options="chartOptions"
                :chart-data="chartData2"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :width="200"
                :height="200"
              /></div
          ></el-col>
        </el-row>
      </div>
    </div>


         </div
      ></el-col>



    </el-row>




  </div>
</template>

<script>
// @ is an alias to /src
// import BarChart from 'components/BarChart.vue'

import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "HomeView",
  components: { Pie },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },

    // val1:'',
    val2: "",
  },
  data() {
    return {
            acc:undefined,
      user:undefined,

      userAuth:undefined,

      show:true,
      dialogVisible: false,
      dialogVisible2: false,

      form: [
        {
          url: "",
        },
      ],

      male: undefined,
      female: undefined,
      total: undefined,
      uniqe: undefined,

      chartData: {
        labels: ["Uniqe View", "Total view"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [40, 20],
          },
        ],
      },

      chartData2: {
        labels: ["Male", "Female"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [40, 60],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      import_rss: "",
      tableData: [
        {
          Name: "Tom",
          DateCreated: "2022-05-03",
          Episodes: "2",
          Status: "open",
          Listens: "no data",
        },
      ],
      activeIndex: "1",

      my_podcast: undefined,
    };
  },


  beforeCreate() {



    this.axios
      .get('http://podcasts.arabicreators.com/api/gender_analysis', {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // this.analysis_podcast = res.data.data;
        this.male = res.data.data.male;
        this.female = res.data.data.female;

        this.chartData2.datasets[0].data[0] = this.male;
        this.chartData2.datasets[0].data[1] = this.female;

        //  alert(this.chartData2.datasets[0].data[1])

        // alert(res.data.data.total)
      });



        this.axios
      .get(`http://podcasts.arabicreators.com/api/analysis_total`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.analysis_podcast = res.data.data;
        this.uniqe = res.data.data.uniqe;
        this.total = res.data.data.total;

        this.chartData.datasets[0].data[0] = this.uniqe;
        this.chartData.datasets[0].data[1] = this.total;

        // alert(this.chartData.datasets[0].data[1])
      });

  },

  mounted() {
   console.log(localStorage.getItem('token'))

    this.axios.get("http://podcasts.arabicreators.com/api/profile", {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then((r => {

        if(r.data.code==200){
      this.acc = r.data.data

      // alert('this.acc')

      this.user = r.data

      if (this.user) {
        localStorage.setItem("user", true)

      } else if (!this.user) {
        localStorage.removeItem("user")
      }

      if (localStorage.getItem('user')) {
        this.userAuth = localStorage.getItem('user')
      } else if (!localStorage.getItem('user')) {
        this.userAuth = null
      }




      localStorage.setItem('profileType',r.data.data.type)


      localStorage.setItem('userName', r.data.data.full_name)

        }


        this.show=!this.show


    }))
  },

  methods: {},
};
</script>


<style lang="scss">
body {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}

.home {
  font-family: "Poppins", sans-serif;
}

.el-menu {
  background: transparent !important;
  border-right: none !important;
}

.el-menu-item i {
  font-size: 24px !important;
  margin-right: 20px !important;
}

.el-col-4 {
  height: 100vh !important;
}

.aisad {
  background-color: #113454;
  height: 100% !important;
  // padding: 20px;
  color: white;

  .img-logo {
    width: 145px;
    height: 40px;
    padding-left: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-item {
  text-align: start;
  font-size: 16px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #161c33 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  i {
    font-size: 25px !important;
  }

  .userInfo {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: #444;
      font-weight: 600;
    }
  }

  .user-image {
    .img-box {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding-left: 20px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;

  .createPodcast {
    width: 100%;
    background-color: #113454 !important;
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin-top: 80px;
    i {
      font-size: 20px;
    }
  }
}

.el-table::before {
  display: none;
}

.el-dialog__body {
  .el-button {
    width: 80%;
    height: 50px;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

table {
  width: 100%;

  thead {
    tr {
      box-shadow: 0 6px 4px -4px #00000029;
      background-color: #fafafa;
      td {
        padding: 10px !important;
      }
    }
  }

  tr {
    box-shadow: 0 6px 4px -4px #00000029;
    cursor: pointer;
    td {
      padding: 8px !important;
    }

    .column {
      border: none !important;
    }
  }

  .fa-ellipsis-vertical {
    color: rgb(182, 182, 182);
  }
}

.el-row {
  margin-bottom: 0 !important;
}

.content-Insights {
  border-radius: 15px;
  margin: 15px;
  margin-top: 30px;
  padding: 20px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .top {
    border-radius: 15px;
    margin: 10px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .border-right {
      border-right: 1px solid #989898;
    }

    .view {
      text-align: left;
      padding: 10px;

      .viewTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
        }
      }

      span {
        font-weight: 700;
        font-size: 22px;
      }
    }
  }

  .view-Insights {
    border-radius: 15px;
    margin: 10px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    h3 {
      text-align: left;
    }
  }
}
</style>
